import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header";
import ContactForm from "../components/contactForm";
import Hero from "../components/hero";
import StyledBackgroundSection from "../components/background404";
import Hr from "../components/hr";
import { Button } from "react-bootstrap";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <StyledBackgroundSection>
      <Header/>
      <Hero
        pretitle='Oops! '
        title='Not&nbsp;Found'
        subtitle="You've requested a page that doesn't&nbsp;exist"
        >
        <br/>
        <Button className="cg-dark" href="/" >Go to Home Page</Button>
      </Hero>
      <Hr/>
    </StyledBackgroundSection>
    
    <ContactForm/> 
  </Layout>
)

export default NotFoundPage
