import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className, children , altImg}) => (
  <StaticQuery
    query={graphql`
      query {
        bgDefault: file(relativePath: { eq: "room3.webp" }) {
          childImageSharp {
            fluid(quality: 94, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const bg = data.bgDefault.childImageSharp.fluid;
      
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={bg}
          backgroundColor={`#333`}
        >
          {children} 
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: top right;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
`
  
export default StyledBackgroundSection